<template>
    <div>
        <ek-dialog
            ref="dialog"
            @ok="submit"
            @close="reset"
            :isEdit="!!unitDto.id"
            :title="unitDto.id ? 'تعديل الوحدة' : 'اضافة الوحدة'"
            @search="setSearch"
            @delete="delUnit(unitDto.id)"
            btnText="اضافة وحدة"
            placeholder="ابحث عن الوحدة"
        >
            <template #body>
                <div>
                    <validationObserver ref="unitForm">
                        <b-form>
                            <ek-input-text
                                v-model="unitDto.name"
                                name="اسم الوحدة"
                                label="اسم الوحدة"
                                placeholder="اسم الوحدة"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'اسم الوحدة مطلوب',
                                    },
                                ]"
                            >
                            </ek-input-text>
                            <ek-input-select
                                v-model="unitDto.subjectId"
                                :options="subjectOptions"
                                placeholder="اختر المادة- الصف"
                                name="اسم المادة-الصف"
                                label="اسم المادة- الصف"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'اسم المادة والصف مطلوب',
                                    },
                                ]"
                            >
                            </ek-input-select>
                            <ek-input-select
                                :options="semesterList"
                                v-model="unitDto.semesterId"
                                placeholder="اختر الفصل"
                                name="الفصل"
                                label="الفصل"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'اسم الفصل مطلوب',
                                    },
                                ]"
                            >
                            </ek-input-select>
                            <!-- <ek-input-select :options="teachersList"
                                   v-model="unitDto.teacherId"
                                   placeholder="اختر اسم المدرس"
                                   name="اسم المدرس"
                                   label="اسم المدرس"
                                   :rules="[
                  {
                    type: 'required',
                    message: 'اسم المدرس مطلوب',
                  },
                ]">
                  </ek-input-select> -->
                            <ek-input-text
                                ref="subOrder"
                                v-if="unitDto.id && unitDto.subjectId"
                                :value="unitDto.order"
                                name="الترتيب"
                                label="الترتيب"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'الترتيب مطلوب',
                                    },
                                ]"
                            >
                            </ek-input-text>
                            <ek-input-text
                                ref="subOrder"
                                v-if="!unitDto.id && unitDto.subjectId"
                                :value="
                                    subjectOptions.find(
                                        (el) => el.id == unitDto.subjectId
                                    ).order
                                "
                                name="الترتيب"
                                label="الترتيب"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'الترتيب مطلوب',
                                    },
                                ]"
                            >
                            </ek-input-text>
                            <b-form-group
                                label="حالة الوحدة"
                                v-slot="{ ariaDescribedby }"
                            >
                                <b-form-radio-group
                                    id="radio-slots"
                                    :options="options"
                                    v-model="unitDto.isFree"
                                    :aria-describedby="ariaDescribedby"
                                    name="حالة الوحدة"
                                >
                                </b-form-radio-group>
                            </b-form-group>
                            <ek-input-text
                                v-if="!unitDto.isFree"
                                v-model="unitDto.price"
                                name="السعر الافتراضي"
                                label="السعر الافتراضي"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'السعر الافتراضي مطلوب',
                                    },
                                ]"
                            >
                            </ek-input-text>
                        </b-form>
                    </validationObserver>
                </div>
            </template>
        </ek-dialog>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
    computed: {
        ...mapState({
            unitDto: ({ units }) => units.unitDto,
            semesterList: ({ settings }) => settings.semesterList.semesters,
            subjectOptions: ({ subjects }) => subjects.subjectOptions,
            teachersList: ({ teachers }) => teachers.teachers,
            isDialogOpen: (state) => state.classes.isDialogOpen,
        }),
        ...mapGetters(["unitsListResult"]),
    },
    data() {
        return {
            options: [
                { text: "مجانية", value: true },
                { text: "غير مجانية ", value: false },
            ],
        };
    },
    methods: {
        submit() {
            this.$refs.unitForm.validate().then((succ) => {
                if (succ) {
                    if (this.unitDto.id) {
                        this.unitDto.order = this.$refs.subOrder.innerVal;
                        this.updateUnit(this.unitDto).then(
                            this.getSubjectOptions()
                        );
                        this.$refs.dialog.close();
                    } else {
                        this.unitDto.order = this.$refs.subOrder.innerVal;
                        this.addUnits(this.unitDto).then(
                            this.getSubjectOptions()
                        );
                        this.$refs.dialog.close();
                        this.$refs.unitForm.reset();
                    }
                }
            });
        },
        reset() {
            this.$refs.unitForm.reset();
            this.$store.commit("SET_UNIT_DTO");
            this.$store.commit("IS_DIALOG_OPEN", false);
            this.$refs.dialog.close();
        },
        delUnit(id) {
            this.deleteUnit(id);
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: [
                    "name",
                    "semester",
                    "studentsCount",
                    "className",
                    "subjectName",
                    "lessonsCount",
                ],
                query,
            });
        },
        ...mapActions([
            "getSubjectOptions",
            "getTeachersList",
            "updateUnit",
            "addUnits",
            "deleteUnit",
            "getSettingSemester",
        ]),
    },
    created() {
        this.getSubjectOptions();
        this.getTeachersList();
        this.getSettingSemester();
    },
    watch: {
        isDialogOpen(nv) {
            if (nv) {
                this.$refs.dialog.open();
            }
        },
    },
};
</script>

<style></style>
